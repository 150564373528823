import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  EndSessionRequest,
  EventMessage,
  EventType,
  InteractionRequiredAuthError
} from '@azure/msal-browser';
import { BehaviorSubject } from 'rxjs';
import { ConnectedUser } from '@types';
import { TranslateService } from '@ngx-translate/core';
import { jwtDecode } from 'jwt-decode';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public connectedUser: BehaviorSubject<ConnectedUser | null> =
    new BehaviorSubject<ConnectedUser | null>(null);

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private translate: TranslateService
  ) {
    // Init Service
    this._activeFirstAccountFromSessionStorage();
    this._loadActiveAccount();

    this.msalBroadcastService.msalSubject$.subscribe({
      next: (event: EventMessage) => {
        switch (event.eventType) {
          case EventType.LOGIN_SUCCESS:
          case EventType.ACQUIRE_TOKEN_SUCCESS: {
            const payload = event.payload as AuthenticationResult;
            this._onLogin(payload.account);
            break;
          }

          case EventType.LOGOUT_SUCCESS: {
            this._onLogout();
            break;
          }

          case EventType.LOGIN_FAILURE:
          case EventType.ACQUIRE_TOKEN_FAILURE: {
            this._onLoginFailure();
            break;
          }

          default: {
            break;
          }
        }
      }
    });
  }

  getUserId(): string | undefined {
    return this.connectedUser?.value?.userId;
  }

  async getAccessToken(): Promise<string | null> {
    const redirectResponse = await this.msalService.instance.handleRedirectPromise();
    if (redirectResponse) {
      return redirectResponse.accessToken;
    }

    const activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount?.idToken) {
      this.login();
      return null;
    }

    const tokenClaims = jwtDecode<{ exp?: number }>(activeAccount.idToken);
    if (tokenClaims.exp && tokenClaims.exp * 1000 < Date.now()) {
      try {
        const refreshTokenResponse = await this.msalService.instance.acquireTokenSilent({
          scopes: environment.authentication.scopes,
          account: activeAccount
        });
        return refreshTokenResponse.accessToken;
      } catch (refreshError) {
        console.error('Failed to refresh token', refreshError);
        return null;
      }
    }

    try {
      const accessTokenResponse = await this.msalService.instance.acquireTokenSilent({
        scopes: environment.authentication.scopes,
        account: activeAccount
      });
      return accessTokenResponse.accessToken;
    } catch (error) {
      console.error(error);
      if (error instanceof InteractionRequiredAuthError) {
        await this.msalService.instance.acquireTokenRedirect({
          scopes: environment.authentication.scopes,
          account: activeAccount
        });
      }
      return null;
    }
  }

  public logout(logoutRequest?: EndSessionRequest) {
    this.msalService.logout(logoutRequest);
  }

  public async login() {
    try {
      await this.msalService.instance.loginRedirect({
        scopes: environment.authentication.scopes
      });
    } catch (error) {
      console.error('Login failed', error);
      this._onLoginFailure();
    }
  }

  private _onLogin(account: AccountInfo | null) {
    if (!account) {
      return;
    }

    this.msalService.instance.setActiveAccount(account);
    this._loadActiveAccount();
  }

  private _onLogout() {
    this.connectedUser.next(null);
  }

  private _onLoginFailure() {
    this.connectedUser.next(null);
    const errorMessage = this.translate.instant('ERROR_MESSAGES.LOGIN_ERROR');
    alert(errorMessage);
  }

  private _accountInfoToConnectedUser(accountInfo: AccountInfo | null): ConnectedUser | null {
    if (!accountInfo || !accountInfo.idTokenClaims) return null;

    return {
      name: accountInfo.idTokenClaims.name,
      initials: accountInfo.idTokenClaims.name
        ?.split(' ')
        .map((n) => n.charAt(0))
        .join(' '),
      userId: accountInfo.idTokenClaims.oid,
      email: accountInfo.idTokenClaims.emails?.[0]
    } as ConnectedUser;
  }

  private _loadActiveAccount() {
    if (!this.msalService.instance.getActiveAccount()) {
      return;
    }
    const activeAccount = this._accountInfoToConnectedUser(
      this.msalService.instance.getActiveAccount()
    );
    this.connectedUser.next(activeAccount);
  }

  private _activeFirstAccountFromSessionStorage() {
    const account = this.msalService.instance.getAllAccounts();
    if (account && account.length > 0) {
      this.msalService.instance.setActiveAccount(account[0]);
    }
  }
}
