import { ChatbotMessage, MessageType } from '@types';
import {
  createAction,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
  props
} from '@ngrx/store';

export interface ChatbotState {
  messages: ChatbotMessage[];
}

export const initialChatState: ChatbotState = {
  messages: []
};

export const chatbotStateSelector = createFeatureSelector<ChatbotState>('chatbot');

export const messagesStateSelector = createSelector(
  chatbotStateSelector,
  (state: ChatbotState) => state.messages
);

export const addMessage = createAction('addMessage', props<{ message: ChatbotMessage }>());

export const updateMessage = createAction('updateMessage', props<{ message: ChatbotMessage }>());

// Ajoutez cette ligne pour créer l'action resetMessages
export const resetMessages = createAction('resetMessages');

// Modifiez votre chatbotReducer pour gérer l'action resetMessages
export const chatbotReducer = createReducer(
  initialChatState,
  on(addMessage, (state, { message }) => ({
    ...state,
    messages: [message, ...state.messages]
  })),
  on(updateMessage, (state, { message }) => ({
    ...state,
    messages: state.messages.map((item) =>
      item.questionId === message.questionId && item.type === MessageType.ANSWER ? message : item
    )
  })),
  on(resetMessages, (state) => ({
    ...state,
    messages: []
  }))
);
