// Prefix
export const PAGE_PREFIX = 'Page: ';
export const EVENT_PREFIX = 'Event: ';

// PAGES
export const CHATBOT_PAGE = 'Chatbot';
export const RA_CHECK_PAGE = 'Risk assessment check';

// RISK ASSESSMENT CHECK
export const IMPORT_PDF = 'Import .PDF file';
export const IMPORT_PDF_SUCCESS = 'Import .PDF file success';
export const IMPORT_PDF_FAILURE = 'Import .PDF file failure';

export const RA_START_EDITING = 'Start risk assessment check editing';
export const RA_CHECK = 'Run check risk assessment';
export const RA_CHECK_SUCCESS = 'Risk assessment check success';
export const RA_CHECK_FAILURE = 'Risk assessment check failure';

export const RA_SOURCES = 'Show risk assessment sources list';
export const RA_OPEN_LINK = 'Open risk assessment source link';

export const COPY_HAZARD = 'Copy complementary hazard';
export const COPY_CONTROL_MEASURE = 'Copy complementary control measure';

// REX
export const FIND_REX = 'Find REX';
export const REX_SUCCESS = 'Find REX success';
export const REX_FAILURE = 'Find REX failure';
export const REX_OPEN_LINK = 'Open REX source link';

// CHATBOT
export const CHATBOT_ASK = 'Ask chatbot question';
export const CHATBOT_STOP_GENERATION = 'Stop chatbot answer generation';
export const CHATBOT_NEW_CONVERSATION = 'Start chatbot new conversation';

export const CHATBOT_COPY = 'Copy chatbot answer';
export const CHATBOT_LIKE = 'Like chatbot answer';
export const CHATBOT_DISLIKE = 'Dislike chatbot answer';

export const CHATBOT_OPEN_LINK = 'Open chatbot answer source link';
export const CHATBOT_CHANGE_SOURCE = 'Change chatbot source type';
