import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import * as pako from 'pako';

@Injectable({
  providedIn: 'root'
})
export class HttpService<T> {
  baseUrl = `${environment.backend_url}`;

  constructor(private http: HttpClient) {}

  post<V>(resource: string, item: T): Observable<V> {
    const compressedPayload = pako.gzip(JSON.stringify(item));

    return this.http.post<V>(`${this.baseUrl}${resource}`, compressedPayload, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip'
      },
      withCredentials: false
    });
  }

  uploadFile(resource: string, file: File): Observable<T> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.http
      .post(`${this.baseUrl}${resource}`, formData, {
        headers: { enctype: 'multipart/form-data' },
        observe: 'response',
        withCredentials: false
      })
      .pipe(map((response) => response.body as T));
  }
}
