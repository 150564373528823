import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ButtonComponent,
  IconButtonComponent,
  IconComponent,
  TileComponent
} from '@totalenergiescode/tds-angular';

@Component({
  selector: 'app-upload-pdf-modal',
  standalone: true,
  imports: [
    ButtonComponent,
    IconComponent,
    IconButtonComponent,
    TileComponent
  ],
  templateUrl: './upload-pdf-modal.component.html'
})
export class UploadPdfModalComponent {
  @Input() title: string = '';
  @Input() message: string = '';
  @Input() buttonActionText: string = '';
  @Input() buttonCancelText: string = '';
  @Output() action = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<Event>();

  cancelAction(event: Event) {
    if (this.title === 'Invalid PDF') this.cancel.emit(event);
  }
}
